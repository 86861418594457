import { SummarizationType } from "@/types/implications";
import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";
interface State {
  categoryPerformance: string;
  companySummarization: SummarizationType | null;
  competitorSummarization: SummarizationType | null;
  loadingSummarize: boolean;
  initializedSummarize: boolean;
  initializedCompetitorSummarization: boolean;
  errorSummarize: string | null;
  errorCompetitorSummary: string | null;
  emotiveCompanySummarization: SummarizationType | null;
  emotiveCompetitorSummarization: SummarizationType | null;
  emotiveInitializedSummarize: boolean;
  emotiveInitializedCompetitorSummarization: boolean;
  emotiveErrorSummarize: string | null;
  emotiveErrorCompetitorSummary: string | null;
}

export const INITIAL_STATE: State = {
  categoryPerformance: "",
  companySummarization: null,
  competitorSummarization: null,
  loadingSummarize: false,
  initializedSummarize: false,
  initializedCompetitorSummarization: false,
  errorSummarize: null,
  errorCompetitorSummary: null,
  emotiveCompanySummarization: null,
  emotiveCompetitorSummarization: null,
  emotiveInitializedSummarize: false,
  emotiveInitializedCompetitorSummarization: false,
  emotiveErrorSummarize: null,
  emotiveErrorCompetitorSummary: null,
};

export interface InsightsSlice extends State {
  setSummarization: (companySummarization: SummarizationType | any) => void;
  setEmotiveSummarization: (
    emotiveCompanySummarization: SummarizationType | any
  ) => void;
  setCompetitorSummarization: (
    competitorSummarization: SummarizationType | null
  ) => void;
  setEmotiveCompetitorSummarization: (
    emotiveCompetitorSummarization: SummarizationType | null
  ) => void;
  setLoadingSummarizeData: (loading: boolean) => void;
}

export const createInsightsSlice: StateCreator<
  InsightsSlice,
  [],
  [],
  InsightsSlice
> = (set, get) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setSummarization: (data) => {
      let companySummarization = data;
      if (companySummarization) {
        companySummarization =
          companySummarization.length > 0
            ? companySummarization[0]
            : companySummarization;
      }

      set({ companySummarization, initializedSummarize: true });
    },
    setCompetitorSummarization: (data) =>
      set({ competitorSummarization: data }),
    setLoadingSummarizeData: (loading) => set({ loadingSummarize: loading }),

    setEmotiveSummarization: (data) => {
      let emotiveCompanySummarization = data;
      if (emotiveCompanySummarization) {
        emotiveCompanySummarization =
          emotiveCompanySummarization.length > 0
            ? emotiveCompanySummarization[0]
            : emotiveCompanySummarization;
      }

      set({ emotiveCompanySummarization, emotiveInitializedSummarize: true });
    },
    setEmotiveCompetitorSummarization: (data) =>
      set({ emotiveCompetitorSummarization: data }),
  };
};
