import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";

interface State {
  redisKeys: { key: string }[];
  initializedRedisKeys: boolean;
}

export interface RedisKeysSlice extends State {
  setRedisKeys: (value: { key: string }[]) => void;
  getRedisKeys: () => { key: string }[];
}

export const INITIAL_STATE: State = {
  redisKeys: [],
  initializedRedisKeys: false,
};

export const createRedisKeysSlice: StateCreator<
  RedisKeysSlice,
  [],
  [],
  RedisKeysSlice
> = (set, get) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    setRedisKeys: (data) => {
      return set({
        redisKeys: data,
      });
    },
    getRedisKeys: () => {
      return get().redisKeys;
    },
  };
};
