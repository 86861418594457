import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";
import { slugify } from "@ui/lib/orgUtils";
import { SingleCategoryChartOutputData } from "@/lib/charts/reviewDataTransformers";

type CategoryLeaderBoardType = {
  [category: string]: SingleCategoryChartOutputData | null;
};
export type CompetitorLeaderBoardType = {
  [competitorSlug: string]: CategoryLeaderBoardType;
};
interface State {
  competitorLeaderBoard: CompetitorLeaderBoardType | null;
  initializedCompetitorLeaderBoard: boolean;
}

export interface CompetitorLeaderBoardSlice extends State {
  getCompetitorLeaderBoard: (
    competitorName?: string
  ) => CategoryLeaderBoardType | null;
  setCompetitorLeaderBoard: (
    competitorLeaderBoard: CompetitorLeaderBoardType | null
  ) => void;
}

export const INITIAL_STATE: State = {
  competitorLeaderBoard: null,
  initializedCompetitorLeaderBoard: false,
};

export const createCompetitorLeaderBoardSlice: StateCreator<
  CompetitorLeaderBoardSlice,
  [],
  [],
  CompetitorLeaderBoardSlice
> = (set, get) => {
  sliceResetFns.add(() => set(INITIAL_STATE));
  return {
    ...INITIAL_STATE,
    getCompetitorLeaderBoard: (competitorName: string = "") => {
      const leaderBoard = get()
        .competitorLeaderBoard as CompetitorLeaderBoardType;
      if (!leaderBoard || !competitorName || competitorName === "") return null;
      return leaderBoard[slugify(competitorName)] as CategoryLeaderBoardType;
    },
    setCompetitorLeaderBoard: (data) =>
      set({
        competitorLeaderBoard: data,
        initializedCompetitorLeaderBoard: true,
      }),
  };
};
