"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@ui/components/ui/form";
import { Input } from "@ui/components/ui/input";
import { SubmitHandler, useForm } from "react-hook-form";
import { signIn } from "next-auth/react";
import { ButtonLoading } from "@ui/components/loading-button";
import { useRouter } from "next/navigation";
import Link from "next/link";
import { buttonVariants } from "@ui/components/ui/button";
import { useTransition } from "react";
import { showToast } from "@ui/components/show-toast";
import { deleteCookie } from "@/actions/squeeze-cookie";
import { resetStoreAll } from "@/stores/resetStoreAll";

export const SignInFormSchema = z.object({
  email: z
    .string({
      required_error: "Email is required",
    })
    .email()
    .trim()
    .toLowerCase(),
  password: z.string({ required_error: "Password is required" }),
});

export type SignInFromType = z.infer<typeof SignInFormSchema>;

export type SignInFieldName = "password" | "email";

const showUserMessage = (props: { success: boolean; message: string }) => {
  showToast(props);
};

export function SignInForm() {
  const [isPending, startTransition] = useTransition();
  const router = useRouter();
  const form = useForm<SignInFromType>({
    defaultValues: { email: "", password: "" },
    resolver: zodResolver(SignInFormSchema),
    mode: "onChange",
  });
  const {
    formState: { isSubmitting },
  } = form;
  const onSubmit: SubmitHandler<SignInFromType> = async ({
    email,
    password,
  }) => {
    await deleteCookie();
    resetStoreAll();
    const response = await signIn("credentials", {
      redirect: false,
      email,
      password,
      callbackUrl: "/discover",
    });
    if (response?.ok) {
      if (response?.error) {
        showUserMessage({ success: false, message: response.error as string });
      } else {
        startTransition(() => {
          router.replace(response?.url ?? "/");
        });
      }
    } else {
      showUserMessage({ success: false, message: "Unauthorized access" });
    }
  };
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="enter you email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Link
          prefetch={false}
          className="w-full text-right text-primary inline-block text-sm underline"
          href="/login/forgotten"
          title="Forgotten password"
        >
          Forgotten password
        </Link>
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  placeholder="enter your password"
                  type="password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div>
          <div className="flex flex-col space-y-6 justify-between">
            <ButtonLoading
              isLoading={isSubmitting || isPending}
              buttonText="Login"
              buttonLoadingText="Authenticating"
            />
            <Link
              prefetch={false}
              className={buttonVariants({ variant: "secondary" })}
              href="/login/sso"
              title="Login with SAML SSO"
            >
              Login with SAML SSO
            </Link>
          </div>
        </div>
      </form>
    </Form>
  );
}
