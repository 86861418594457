"use client";

import { useRef } from "react";
import { useAppStore } from "@/stores/useAppStore";
import { SessionStateType } from "@/stores/sliceUserStore";

function UserStoreInitializer(params: { state: SessionStateType | null }) {
  const { state } = params;
  const { initializedUser } = useAppStore.getState();
  const initRef = useRef(initializedUser);
  if (!initRef.current) {
    initRef.current = true;
    useAppStore.setState({ ...state, initializedUser: true });
  }
  return null;
}

export default UserStoreInitializer;
