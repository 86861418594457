"use client";
import { SessionProvider as NextSessionProvider } from "next-auth/react";
import { Session } from "next-auth";
import UserStoreInitializer from "./initialize-stores/user-store-initializer";
import { SessionStateType } from "@/stores/sliceUserStore";

type SessionProviderProps = {
  children: React.ReactNode;
  session: Session | null;
  state: SessionStateType | null;
};

export const SessionProvider = ({
  children,
  session,
  state,
}: SessionProviderProps) => {
  return (
    <NextSessionProvider session={session}>
      <UserStoreInitializer state={state} />
      {children}
    </NextSessionProvider>
  );
};

export default SessionProvider;
